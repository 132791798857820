import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import http from "../services/http";
import config from "../config/api.json";
import jwtDecode from "jwt-decode";

const AdminLogin = (props) => {
  const [adminUsername, setAdminUsername] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("x-app-access")) {
      localStorage.setItem("x-app-access", config.appAccessKey);
    }
    const appAccess = jwtDecode(localStorage.getItem("x-app-access"));
    if (appAccess.hasOwnProperty("isAdmin") && appAccess.isAdmin) {
      setRedirect(true);
    }
  }, []);

  const handleLogin = async () => {
    try {
      const reqBody = {
        adminUsername,
        adminPassword,
      };
      const url = `${config.apiUrl}/admin/login`;

      const { data: loginRes } = await http.post(url, reqBody);
      const { appAccessToken } = loginRes;

      localStorage.setItem("x-app-access", appAccessToken);
      http.setAppAccess(appAccessToken);

      props.history.push("/admin");
    } catch (err) {
      console.log("UH OH", err);
    }
  };

  if (redirect) {
    return <Redirect to="/admin" />;
  }

  return (
    <div className="login-form__content-wrapper">
      <div className="admin-login-child">
        <h1>Admin Login</h1>
        <form>
          <label>
            Username: &nbsp;
            <input
              type="text"
              value={adminUsername}
              onChange={(e) => setAdminUsername(e.target.value)}
            />
          </label>
          <label>
            Password: &nbsp;
            <input
              type="text"
              value={adminPassword}
              onChange={(e) => setAdminPassword(e.target.value)}
            />
          </label>
        </form>
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
};

export default AdminLogin;
