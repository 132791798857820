import React, { useState, useEffect } from "react";
import http from "../services/http";
import config from "../config/api.json";
import ContactForm from "./common/contactForm";
import { Link } from "react-router-dom";
import Footer from "./common/footer";

const FloorPlan = (props) => {
  const [floorPlanDetails, setFloorPlanDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const { id: floorPlanID } = props.match.params;

  useEffect(() => {
    const fetchFloorPlanDetails = async () => {
      setLoading(true);
      const url = `${config.apiUrl}/floorPlanDetails/${floorPlanID}`;
      const floorPlansRes = await http.get(url);
      setFloorPlanDetails(floorPlansRes.data);
      setLoading(false);
    };

    fetchFloorPlanDetails();
  }, [floorPlanID]);

  return (
    <div className="floor-plan__content-wrapper">
      {loading && <h2>Loading...</h2>}
      {!loading && (
        <div className="floor-plan-detail">
          <h1>{floorPlanDetails.name}</h1>
          <Link to="/">Back to Home</Link>
          <p>{floorPlanDetails.description}</p>
          <p>Bedrooms: {floorPlanDetails.bedrooms}</p>
          <p>Bathrooms: {floorPlanDetails.bathrooms}</p>
          <p>Square Feet: {floorPlanDetails.square_feet}</p>
          <p>
            Rent: {floorPlanDetails.rent_low} - {floorPlanDetails.rent_high}
          </p>
          <img
            alt={`${floorPlanDetails.name} Floor Plan`}
            src={floorPlanDetails.floor_plan_image_link}
          />
        </div>
      )}
      <ContactForm />
      <Footer />
    </div>
  );
};

export default FloorPlan;
