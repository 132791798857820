import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import media from "../../config/media.json";

const FullCar = () => {
  return (
    <div className="fullcar">
      <Carousel>
        <Carousel.Item>
          <div className="carimagediv">
            <img
              className="d-block w-100 carimage1"
              src={`${media.mediaUrl}/kitchen.JPG`}
              alt="First slide"
            />
          </div>
          <Carousel.Caption className="carcaption">
            <h3>Family First</h3>
            <p>
              A family-friendly environment for you and your loved ones to grow
              and thrive in.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carimagediv">
            <img
              className="d-block w-100 carimage"
              src={`${media.mediaUrl}/extLandscape.JPG`}
              alt="Third slide"
            />
          </div>
          <Carousel.Caption className="carcaption">
            <h3>Quality Community</h3>
            <p>A beautiful neighborhood you can be proud to live in.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carimagediv">
            <img
              className="d-block carimage2"
              src={`${media.mediaUrl}/living.JPG`}
              alt="Third slide"
            />
          </div>
          <Carousel.Caption className="carcaption">
            <h3>Stunning Homes</h3>
            <p>Choose from our unique selection of top-end floorplans.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default FullCar;
