import React, { useState, useEffect } from "react";
import http from "../services/http";
import config from "../config/api.json";
import { sortBy, filter, findIndex, update } from "lodash";
import { Redirect, Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import logo from "./../images/FinalLogo.png";

const Admin = () => {
  const [interestedContacts, setInterestedContacts] = useState([]);
  const [interestedContactsView, setInterestedContactsView] = useState([]);
  const [loading, setLoading] = useState([]);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const fetchInterestedContacts = async () => {
      setLoading(true);
      const adminToken = localStorage.getItem("x-app-access");
      // const httpParams = {
      //   headers: {
      //     "x-app-access": adminToken,
      //   },
      // };
      const url = `${config.apiUrl}/admin/interestedContacts`;
      const { data: interestedContactsRes } = await http.get(url);
      setInterestedContacts(interestedContactsRes);
      setLoading(false);
    };

    const appAccess = jwtDecode(localStorage.getItem("x-app-access"));
    if (!appAccess.hasOwnProperty("isAdmin") || !appAccess.isAdmin) {
      setRedirect(true);
    } else {
      fetchInterestedContacts();
    }
  }, []);

  useEffect(() => {
    const unackContacts = filter(interestedContacts, { acknowledged: false });
    const unackContactsSort = sortBy(unackContacts, ["sys_inserted"]);
    const ackContacts = filter(interestedContacts, { acknowledged: true });
    const ackContactsSort = sortBy(ackContacts, ["sys_inserted"]);
    const interestedContactsViewSort = [
      ...unackContactsSort,
      ...ackContactsSort,
    ];
    setInterestedContactsView(interestedContactsViewSort);
  }, [interestedContacts]);

  const handleAckClick = async (event) => {
    try {
      const contactID = parseInt(event.target.name.split("_")[1]);

      const adminToken = localStorage.getItem("x-app-access");
      // const httpParams = {
      //   headers: {
      //     "x-app-access": adminToken,
      //   },
      // };
      const url = `${config.apiUrl}/admin/ackInterestedContact/${contactID}`;

      await http.put(url, {});

      setInterestedContacts([]);
      const contactIndex = findIndex(interestedContacts, { id: contactID });
      const updatedContacts = update(
        interestedContacts,
        `[${contactIndex}].acknowledged`,
        () => {
          return true;
        }
      );
      setInterestedContacts(updatedContacts);
      toast.success("Contact acknowledged");
    } catch (err) {
      toast.error("Uh oh.. something went wrong");
    }
  };

  if (redirect) {
    return <Redirect to="/admin/login" />;
  }

  return (
    <div className="admin__content-wrapper">
      <Link to="/">
        <img src={logo} alt="" />
      </Link>
      <h1>
        Northside Terrace Admin Page
        <span>&nbsp;&nbsp; Interested Contacts</span>
      </h1>
      <div className="admin-child">
        {loading && <h3>Loading...</h3>}
        {!loading && (
          <div className="interested-contacts">
            <table>
              <thead>
                <tr className="headRow">
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Preferred Floor Plan</th>
                  <th>Message</th>
                  <th>Acknowledge</th>
                </tr>
              </thead>
              <br />
              <tbody>
                {interestedContactsView.map((contact) => {
                  return (
                    <tr key={contact.id}>
                      <td>
                        {contact.first_name} {contact.last_name}
                      </td>
                      <td>{contact.email}</td>
                      <td>{contact.phone}</td>
                      <td>{contact.floor_plan_name}</td>
                      <td>{contact.message}</td>
                      <td>
                        <button
                          name={`ack_${contact.id}`}
                          disabled={contact.acknowledged}
                          onClick={handleAckClick}
                        >
                          Acknowledge
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
