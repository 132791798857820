import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { TiLocationArrowOutline } from "react-icons/ti";
import media from "../../config/media.json";

const Footer = (props) => {
  return (
    <div className="footerContainer">
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }} sm={{ span: 12 }}>
            <center>
              <AiOutlineMail /> <p>info@northsideterrace.com</p>
              <TiLocationArrowOutline />
              <p>
                2985 N Hwy 7<br /> North Vernon, IN 47265
              </p>
            </center>
          </Col>
        </Row>
      </Container>
      <center>
        <img className="t3-logo" src={`${media.mediaUrl}/t3_logo.png`} alt="" />
        <div className="cccplug">
          Site designed and created by{" "}
          <a href="https://www.circlecitycoders.com" target="_blank">
            Circle City Coders
          </a>
        </div>
      </center>
    </div>
  );
};

export default Footer;
