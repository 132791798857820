import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import config from "./config/api.json";
import http from "./services/http";

// Components
import NotFound from "./components/notFound";
import Home from "./components/home";
import FloorPlan from "./components/floorPlan";
import AdminLogin from "./components/adminLogin";
import Admin from "./components/admin";
import { ToastContainer } from "react-toastify";
import Navbar from "./components/navbar";

function App() {
  return (
    <main className="APPcontainer" id="APPcontainer">
      <Switch>
        <Route
          path="/admin/login"
          render={(props) => <AdminLogin {...props} />}
        />
        <Route path="/admin" render={(props) => <Admin {...props} />} />
        <Route
          path="/floorPlan/:id"
          render={(props) => <FloorPlan {...props} />}
        />
        <Route path="/" render={(props) => <Home {...props} />} exact />
        <Route path="*" component={NotFound} />
      </Switch>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </main>
  );
}

export default App;
