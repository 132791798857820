import React, { useState, useEffect, Fragment } from "react";
import http from "../services/http";
import config from "../config/api.json";
import { sortBy, filter, range } from "lodash";
import ContactForm from "./common/contactForm";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Modal, Button } from "react-bootstrap";
import FullCar from "./common/fullCarousel";
import FPCarousel from "./fpCarousel";
import Footer from "./common/footer";
import Gallery from "./gallery";
// import FpModal from "./fpModal";
// import FloorPlan from "./floorPlan";
import Navbar from "./navbar";
import media from "../config/media.json";

const Home = () => {
  const [floorPlans, setFloorPlans] = useState([]);
  const [floorPlanView, setFloorPlanView] = useState([]);
  const [floorPlanDetails, setFloorPlanDetails] = useState({});
  const [floorPlanTour, setFloorPlanTour] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(["all"]);
  const { id: floorPlanID } = useState([]);
  // const [selectedFP, setSelectedFP] = useState(["fpImage"]);
  // const [fpList, setFPList] = useState(["Lexington", "Concord"]);

  useEffect(() => {
    const fetchFloorPlans = async () => {
      setLoading(true);
      const url = `${config.apiUrl}/floorPlans`;

      const floorPlansRes = await http.get(url);

      const floorPlanOrdered = sortBy(floorPlansRes.data, [
        "bedrooms",
        "square_feet",
        "name",
      ]);
      setFloorPlans(floorPlanOrdered);
      setFloorPlanView(floorPlanOrdered);
      setLoading(false);
    };

    const fetchFloorPlanDetails = async () => {
      setLoading(true);
      const httpParams = {
        headers: {
          "x-app-access": config.appAccessKey,
        },
      };
      const url = `${config.apiUrl}/floorPlanDetails/${floorPlanID}`;
      const floorPlansRes = await http.get(url, httpParams);
      setFloorPlanDetails(floorPlansRes.data);
      setLoading(false);
    };

    const fetchFloorTour = async () => {
      setLoading(true);
      const httpParams = {
        headers: {
          "x-app-access": config.appAccessKey,
        },
      };
      const url = `${config.apiUrl}/floorPlanTour/${floorPlanID}`;
      const floorPlansRes = await http.get(url, httpParams);
      setFloorPlanTour(floorPlansRes.data);
      setLoading(false);
    };

    fetchFloorPlans();
    // fetchFloorPlanDetails();
    // fetchFloorPlanTour();
  }, []);

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }

  const images = importAll(
    require.context("../images/floorPlanPics/", false, /\.(png|jpe?g|svg)$/)
  );

  const handleTabClick = (event) => {
    const { name } = event.target;
    if (name === "all") {
      setFloorPlanView(floorPlans);
    } else {
      const bedCount = parseInt(name.split("_")[1]);
      const floorPlansFiltered = filter(floorPlans, { bedrooms: bedCount });
      const floorPlansOrdered = sortBy(floorPlansFiltered, [
        "square_feet",
        "name",
      ]);
      setFloorPlanView(floorPlansOrdered);
    }
    setActiveTab(name);
  };

  // const handleFPSelect = (event) => {
  //   const { name } = event.target;
  //   setSelectedFP(name);
  //   console.log(selectedFP);
  // };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        className="modal-container"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h1>{props.fpName}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <iframe
            width="100%"
            src={props.tourLink}
            frameborder="0"
            allowfullscreen
            allow="xr-spatial-tracking"
          ></iframe>
          <center>
            <p>{props.fpDesc}</p>
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function App(props) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
      <>
        <Button className="fpLink" onClick={() => setModalShow(true)}>
          {props.fpName}
        </Button>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          fpName={props.fpName}
          fpDesc={props.fpDesc}
          // imageLink={props.imageLink}
          tourLink={props.tourLink}
        />
      </>
    );
  }

  return (
    <React.Fragment>
      <Navbar />
      <div className="home__content-wrapper" id="top">
        <FullCar />
        <div id="welcome" className="anchor"></div>
        <Container className="welcomeContainer">
          <h1 className="welcomeHeader">
            Welcome Home. <br />
            <span>Welcome to Northside Terrace.</span>
          </h1>
          <Row className="welcomeRow divRight">
            <Col
              md={{ span: 10, offset: 1 }}
              sm={{ span: 10, offset: 1 }}
              className=""
            >
              <h2>Our Features Include</h2>
              <br />
              <ul>
                <li>open concept living area</li>
                <li>fully equiped kitchen with pantry</li>
                <li>stainless steel appliances</li>
                <li>luxury vinyl wood plank floors throughout</li>
                <li>central heating and air conditioning</li>
                <li>washer and dryer</li>
                <li>ample closet space and storage</li>
                <li>window blinds</li>
                <li>private entrances</li>
                <li>private patios with outdoor storage</li>
                <li>spacious outdoor common area</li>
                <li>security lighting</li>
                <li>close to town and easy access to all local highways</li>
              </ul>
            </Col>
          </Row>
        </Container>

        <div id="gallery" className="anchor"></div>
        {/* <Gallery /> */}

        {loading && <h3>Loading...</h3>}
        {!loading && (
          <React.Fragment>
            <div id="floorPlans" className="anchor"></div>
            <Container className="floorPlansContainer">
              <Row>
                <Col md={{ span: 10, offset: 1 }} sm={{ span: 12 }}>
                  <center>
                    <h2>Floor Plans</h2>
                  </center>
                  <div className="floor-plans">
                    {/* <div className="tabs">
                      <button
                        id="all"
                        className={activeTab == "all" ? "active" : ""}
                        name="all"
                        onClick={handleTabClick}
                      >
                        All
                      </button>
                      <button
                        id="bed_1"
                        name="bed_1"
                        className={activeTab == "bed_1" ? "active" : ""}
                        onClick={handleTabClick}
                      >
                        1 Bedroom
                      </button>
                      <button
                        id="bed_2"
                        className={activeTab == "bed_2" ? "active" : ""}
                        name="bed_2"
                        onClick={handleTabClick}
                      >
                        2 Bedrooms
                      </button>
                      <button
                        id="bed_3"
                        className={activeTab == "bed_3" ? "active" : ""}
                        name="bed_3"
                        onClick={handleTabClick}
                      >
                        3 Bedrooms
                      </button>
                      <button
                        id="bed_4"
                        className={activeTab == "bed_4" ? "active" : ""}
                        name="bed_4"
                        onClick={handleTabClick}
                      >
                        4 Bedrooms
                      </button>
                    </div> */}
                    <Table responsive className="floorPlanTable">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th className="desc-header">Description</th>
                          <th className="bed-bath-head-dtop">Beds/Baths</th>
                          <th className="bed-bath-head-mobile">
                            Beds/
                            <br />
                            Baths
                          </th>
                          <th className="bed-bath-head-dtop">Square Feet</th>
                          <th className="bed-bath-head-mobile">
                            Square
                            <br />
                            Feet
                          </th>
                          <th className="bed-bath-head-dtop">Price</th>
                          <th className="bed-bath-head-mobile">Price</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {floorPlanView.map((floorPlan) => {
                          return (
                            <tr key={floorPlan.id}>
                              <td className="tableCell">{floorPlan.name}</td>
                              <td className="tableCell desc-cell">
                                {floorPlan.description}
                              </td>
                              <td className="tableCell">
                                {floorPlan.bedrooms + " Bed "} <br />
                                {floorPlan.bathrooms + " Bath "}
                              </td>
                              <td className="tableCell">
                                {floorPlan.square_feet}
                              </td>
                              <td className="tableCell">
                                {floorPlan.coming_soon
                                  ? "Coming Soon!"
                                  : `${
                                      floorPlan.rent_low.split(".")[0]
                                    } per Month`}
                              </td>
                              <td className="tableCell virtual-tour">
                                {!floorPlan.coming_soon &&
                                  floorPlan.virtual_tour_link && (
                                    <App
                                      fpName="Virtual Tour"
                                      // imageLink={
                                      //   "../images/" + floorPlan.name + ".jpg"
                                      // }`
                                      fpDesc={floorPlan.description}
                                      tourLink={floorPlan.virtual_tour_link}
                                    />
                                  )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <h4>Additional Info</h4>
                    <ul>
                      <li>Utilities not included.</li>
                      <li>No Bankruptcies.</li>
                      <li>No Felonies.</li>
                    </ul>
                  </div>
                </Col>
                {/* <Col md={{ span: 6 }} sm={{ span: 12 }}>
                  <FPCarousel />
                </Col> */}
              </Row>
            </Container>
          </React.Fragment>
        )}

        <div id="contact" className="anchor"></div>
        <ContactForm />

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Home;
