import React, { useState, useEffect } from "react";
import http from "../../services/http";
import config from "../../config/api.json";
import { sortBy } from "lodash";
import { toast } from "react-toastify";
import { Container, Form, Button, Row, Col } from "react-bootstrap";

const ContactForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [floorPlanID, setFloorPlanID] = useState("");
  const [message, setMessage] = useState("");
  const [floorPlans, setFloorPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchFloorPlans = async () => {
      setLoading(true);
      // const httpParams = {
      //   headers: {
      //     "x-app-access": config.appAccessKey,
      //   },
      // };
      const url = `${config.apiUrl}/floorPlans`;
      const floorPlansRes = await http.get(url);
      setFloorPlans(floorPlansRes.data);
      setLoading(false);
    };

    fetchFloorPlans();
  }, []);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setButtonDisabled(true);
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const emailValidation = re.test(String(email).toLowerCase());

      if (!emailValidation) {
        return toast.error("Invalid Email");
      }

      const reqBody = {
        firstName,
        lastName,
        email,
        phone,
      };

      if (floorPlanID !== "") {
        reqBody.floorPlanID = floorPlanID;
      }

      if (message !== "") {
        reqBody.message = message;
      }

      const url = `${config.apiUrl}/newInterestedContact`;

      await http.post(url, reqBody);

      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setFloorPlanID("");
      setMessage("");

      toast.success("Submission successful. Thank you!");
      setButtonDisabled(false);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
        setButtonDisabled(false);
      } else {
        toast.error("Unexpected error. Please try again.");
        setButtonDisabled(false);
      }
    }
  };

  return (
    <Container>
      <div id="contact" className="anchor"></div>
      <div className="contact-form">
        <h2>
          <span>Interested?</span> <br />
          Fill out the form for more information <br />
          or to request a rental application.
        </h2>
        {loading && <h3>Loading...</h3>}
        {!loading && (
          <Form>
            <div className="form-content">
              <form>
                <Row className="justify-content-md-center">
                  <Col md={{ span: 3, offset: 0 }} sm={{ span: 12 }}>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        value={firstName}
                        placeholder="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={{ span: 3, offset: 0 }} sm={{ span: 12 }}>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        value={lastName}
                        placeholder="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col md={{ span: 3, offset: 0 }} sm={{ span: 12 }}>
                    <Form.Group>
                      <Form.Control
                        type="email"
                        value={email}
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={{ span: 3, offset: 0 }} sm={{ span: 12 }}>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        value={phone}
                        placeholder="Phone Number"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col md={{ span: 3, offset: 0 }} sm={{ span: 12 }}>
                    <Form.Control
                      as="select"
                      placeholder="Preferred Floor Plan"
                      value={floorPlanID}
                      onChange={(e) => setFloorPlanID(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select a Floor Plan
                      </option>
                      {sortBy(floorPlans, ["name"]).map((floorPlan) => {
                        return (
                          <option key={floorPlan.id} value={floorPlan.id}>
                            {floorPlan.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col md={{ span: 6, offset: 0 }} sm={{ span: 12 }}>
                    <Form.Group>
                      <Form.Control
                        as="textarea"
                        rows="1"
                        value={message}
                        className="textarea"
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Questions or Comments"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </form>
              <Row>
                <Col md={{ span: 1, offset: 3 }} sm={{ span: 12 }}>
                  <Button
                    className="submitBtn"
                    type="submit"
                    disabled={buttonDisabled}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </div>
    </Container>
  );
};

export default ContactForm;
