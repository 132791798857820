import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import logo from "./../images/FinalLogo.png";

class Navbar extends Component {
  state = {};
  render() {
    return (
      <div className="navbar">
        <Row className="navRow">
          <Col className="navItem logohead" md={{ span: 4, offset: 1 }}>
            <Link to="/" className="navheader">
              <img src={logo} alt="" />
            </Link>
          </Col>
          <Col className="navItem navMobile" md={{ span: 2, offset: 0.5 }}>
            <a href="/#top">Welcome</a>
          </Col>
          {/* <Col className="navItem navMobile" md={{ span: 2, offset: 0 }}>
            <a href="/#gallery">Gallery</a>
          </Col> */}
          <Col className="navItem navMobile" md={{ span: 2, offset: 0 }}>
            <a href="/#floorPlans">Floor Plans</a>
          </Col>
          <Col className="navItem navMobile" md={{ span: 2, offset: 0 }}>
            <a href="/#contact">Contact</a>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Navbar;
